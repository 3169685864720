const React = require("react");

exports.onRouteUpdate = ({ location, prevLocation }) => {

    function addCookieConsent() {
        const scriptId = "cookieyes";
        const existingScript = document.getElementById(scriptId);
        if (!existingScript) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            // script.src = "https://cdn.termsfeedtag.com/plugins/pc/v1/2eb71b8d5fa3474c8c9d25a8cecdeddd/plugin.js";
            script.src = "https://cdn-cookieyes.com/client_data/c64b5b39b6b37c3be937c02b/script.js";
            script.id = scriptId;
            // script.async = true;
            document.head.appendChild(script);
        }

        const linkId = "dnsos";
        const existingLink = document.getElementById(linkId);
        console.log(existingLink);
    }

    addCookieConsent();

    if (location.pathname === "/buy/") {
        handleBuyPageUpdate();
    }
}

function handleBuyPageUpdate() {

    function fastSpringCallBack(data) {
    }

    function addSBL() {
        const scriptId = "fsc-api";
        const existingScript = document.getElementById(scriptId);
        if (!existingScript) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js";
            script.id = scriptId;
            script.dataset.storefront = "fileographer.onfastspring.com/popup-fileographer";
            window.fastSpringCallBack = fastSpringCallBack;
            script.setAttribute("data-data-callback", "fastSpringCallBack");
            document.head.appendChild(script);
        } else {
            // location.reload();
        }
    }

    addSBL();

    // function addProduct() {
    //     fastspring.builder.reset();
    //     fastspring.builder.add("fileographer-v-1-license-code");
    //     fastspring.builder.checkout();
    // }
    // function maybeAddProduct() {
    //     if (typeof fastspring !== "undefined") {
    //         addProduct();
    //     } else {
    //         setTimeout(maybeAddProduct, 200);
    //     }
    // }
    // maybeAddProduct();

}
